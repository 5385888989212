// sass-lint:disable mixins-before-declarations
.course-in-program {
  width: 100%;
  padding: 100px 0 50px;
  background: white url("#{$static-path}/images/course-carousel-bg.jpg")
    no-repeat 50% 50%;
  min-height: 400px;
  overflow: hidden;
  position: relative;
  color: black;
  background-size: cover;

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    content: "";
    height: 10px;
    background: linear-gradient(
      to right,
      rgb(61 189 191 / 100%) 0%,
      rgb(241 186 70 / 100%) 100%
    );
  }

  .head {
    text-align: center;
    padding: 0 0 20px;

    h3 {
      margin: 0 0 20px;
    }
  }

  h1 {
    color: $primary;
    margin: 0 0 10px;

    @include media-breakpoint-up(lg) {
      margin: 0 0 15px;
    }
  }

  .btn {
    font-size: x-large;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
  }
}

.course-slider {
  position: relative;
  margin: 0 -15px 50px;

  &::after {
    display: block;
    clear: both;
    content: "";
  }

  .slide {
    cursor: pointer;
    float: left;
    width: 33.333%;
    padding: 0 15px;
  }

  .slide-holder {
    height: 100%;
    position: relative;
    background: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px 0 rgb(0 0 0 / 10%);
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    color: $gray;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  img {
    width: 100%;
    display: block;
    margin: 0 0 15px;
  }

  .title {
    font-size: 28px;
    font-weight: 500;
    margin: 0 0 20px;
    line-height: 30px;
  }

  p {
    margin: 0 0 10px;
    min-height: 89px;
    padding-bottom: 40px;
    padding-top: 20px;
    text-align: start;
  }

  .detail-link {
    position: absolute;
    bottom: 20px;
    left: 15px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: $primary;

    &::after {
      font-family: "Material Icons";
      content: "arrow_circle_up";
      text-transform: none;
      transform: rotate(90deg);
      font-size: 20px;
      display: inline-block;
      vertical-align: top;
      line-height: 21px;
      margin: 0 0 0 5px;
    }
  }
}
