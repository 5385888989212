// sass-lint:disable mixins-before-declarations

.footer {
  background: $footer-bg;
  color: white;
  font-weight: 500;
  padding: 0 0 15px;

  .footer-top {
    background: $very-light-gray;
    overflow: hidden;
    position: relative;
    color: black;
    padding: 40px 0 0;

    &::after {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      content: "";
      height: 10px;
      background: linear-gradient(
        to right,
        rgb(61 189 191 / 100%) 0%,
        rgb(241 186 70 / 100%) 100%
      );
    }

    .content-holder {
      max-width: 966px;

      .row {
        justify-content: space-between;

        .col-sm-6 {
          padding: 0 15px 20px;
          text-align: center;

          @include media-breakpoint-up(sm) {
            max-width: 400px;
            text-align: left;
          }

          img {
            margin: 0 0 15px;
            width: 78px;

            @include media-breakpoint-up(sm) {
              float: left;
              margin: 0 45px 0 0;
            }
          }

          .info {
            overflow: hidden;
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
          }
        }
      }
    }

    a {
      color: $primary;
    }

    h2 {
      color: $primary;
      margin: 0 0 5px;
      font-size: 28px;
      line-height: 32px;
      font-weight: 600;
    }

    p {
      margin: 0;
    }
  }

  .footer-bottom {
    padding: 15px 0 5px;

    &::after {
      clear: both;
      content: "";
      display: block;
    }
  }

  a {
    color: white;
  }

  .mit-info {
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    padding: 0 0 20px;

    @include media-breakpoint-up(md) {
      padding: 0;
      float: left;
      width: 44%;
      text-align: left;
    }

    img {
      width: 129px;
      height: 33px;
      margin: 0 auto 15px;

      @include media-breakpoint-up(md) {
        margin: 5px 15px 0 0;
        float: left;
      }

      @include media-breakpoint-up(lg) {
        margin: 5px 26px 0 0;
      }
    }

    address {
      overflow: hidden;
      display: block;
      margin: 0;
    }
  }

  .right-info {
    text-align: center;
    padding: 0 0 20px;

    @include media-breakpoint-up(md) {
      float: right;
      text-align: left;
      padding: 0;
    }
  }

  .footer-nav {
    padding: 0;
    margin: 0;
    list-style: none;

    @include media-breakpoint-up(md) {
      float: left;
    }

    li {
      display: inline-block;
      vertical-align: top;
      margin: 0 0 0 10px;
      font-size: 18px;
      line-height: 28px;

      @include media-breakpoint-up(lg) {
        margin: 0 0 0 15px;
      }

      &:first-child {
        margin: 0;
      }
    }
  }

  .hbspt-form {
    @include media-breakpoint-up(md) {
      float: left;
      margin: 0 0 0 65px;
      width: 208px;
    }

    /* stylelint-disable selector-class-pattern */
    label,
    .hs_error_rollup {
      display: none;
    }
    /* stylelint-enable selector-class-pattern */

    .hs-form input[type="email"] {
      border: none;
      border-bottom: 1px solid $footer-border;
      background: none;
      color: white;
      height: 30px;
      font-size: 16px;
      line-height: 30px;
      outline: none;
      font-family: inherit;
      width: 100%;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .hs_email {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 43px);
      margin: 0 10px 0 0;

      @include media-breakpoint-up(md) {
        width: 180px;
      }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .hs_submit {
      display: inline-block;
      vertical-align: top;
      margin: 6px 0 0;
      line-height: 0;
    }

    .hs-form .hs-button {
      color: white;
      background: url("#{$static-path}/images/arrow-circle-right.svg") no-repeat;
      background-size: 18px 18px;
      border: none;
      padding: 0;
      opacity: 0.7;
      width: 18px;
      height: 18px;
      cursor: pointer;
      text-indent: -9999px;
      overflow: hidden;
    }

    .submitted-message {
      margin-left: 0;
      color: $blue;
      font-family: Rajdhani;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      width: auto;
      padding: 10px 0 0;
    }

    .no-list {
      margin: 0;
      padding: 5px 0 0;
      list-style: none;

      label {
        display: block;
        font-size: 12px;
        line-height: 15px;
        color: $primary;
      }
    }
  }

  .footer-sub-nav {
    padding: 0;
    margin: 0 0 5px;
    list-style: none;
    text-align: center;
    font-size: 15px;
    line-height: 20px;

    li {
      display: inline-block;
      vertical-align: top;

      &::before {
        padding: 0 10px;
        content: "|";
      }

      &:first-child {
        &::before {
          display: none;
        }
      }
    }
  }

  .copyright {
    display: block;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
  }
}
