// sass-lint:disable mixins-before-declarations
// sass-lint:disable no-vendor-prefixes

$gray-light: #d9e0e3;
$text-gray-dark: #6b6969;
$text-gray-light: #d2d0d1;

.certificate-page {
  background-color: #edf1f2;

  .certificate-logo {
    display: block;
    width: 199px;
    height: 56px;
    margin: 0 auto 15px;
    position: relative;

    img {
      display: block;
      width: 100%;

      @media (width <= 480px) {
        min-height: 40px;
        height: 40px;
      }
    }

    a {
      position: absolute;
      left: 0;
      top: 10px;
      width: 103px;
      height: 56px;

      &.xpro {
        left: 149px;
        top: 22px;
        width: 98px;
        height: 33px;
      }
    }
  }

  .certificate-dual-logo {
    display: flex;
    max-width: 625px;
    margin: 0 auto 15px;
    align-items: center;
    justify-content: center;

    .column {
      img {
        width: auto;
        min-height: 56px;
        height: 56px;

        @media (width <= 480px) {
          min-height: 40px;
          height: 40px;
        }
      }
    }

    > :first-child {
      margin-right: 40px;

      @media (width <= 480px) {
        margin-right: 20px;
      }
    }
  }
}

.cer-head {
  background: white;
  padding: 20px 15px 5px;
}

.cer-user-info {
  border: 1px solid #979797;
  background-color: $navy-blue;
  padding: 20px 0;
  font: 14px/17px Helvetica;
  color: white;

  h2 {
    margin: 0 0 10px;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
  }

  p {
    margin: 0;
  }

  .user-info-holder {
    margin: 0 auto;
    max-width: 1000px;
    padding: 0 15px;
  }
}

.certificate-wrapper {
  padding: 20px 15px;
  margin: 0 auto;
  max-width: 1120px;

  .certificate {
    border: 2px solid $gray-light;
    background: white;
    padding: 35px 15px 0;
    font-family: Georgia, "Times New Roman", Times, serif;
    text-align: center;
    color: black;

    .institute-logo {
      display: block;
      max-width: 202px;
      margin: 0 auto 30px;

      img {
        display: block;
        width: 100%;
      }
    }

    .certificate-holder {
      margin: 0 auto;
      max-width: 730px;

      .validation-link {
        font-size: 16px;

        a {
          text-decoration: none;
          color: $blue;
        }
      }

      .certify-text {
        display: block;
        font-size: 18px;
        line-height: 25px;
        color: $text-gray-dark;
        font-style: italic;
      }

      .institute-text {
        display: block;
        font-size: 18px;
        line-height: 25px;
        color: $text-gray-dark;
        margin: 0 0 15px;
      }

      .certify-name {
        display: block;
        font-size: 50px;
        line-height: 60px;
        font-weight: 600;
        margin: 0 0 40px;
      }

      .degree-text {
        display: block;
        font-size: 38px;
        line-height: 45px;
        font-weight: 600;
      }

      .success-text {
        display: block;
        font-size: 18px;
        line-height: 25px;
        color: $text-gray-dark;
        font-style: italic;
        margin: 0 0 10px;
      }

      .program-degree-text {
        display: block;
        font-size: 20px;
        line-height: 25px;
      }

      .award-text {
        display: block;
        font-size: 20px;
        line-height: 25px;
        margin: 20px 0;
      }

      .certify-by {
        padding-top: 35px;
        padding-bottom: 35px;
        font-size: 12px;
        line-height: 17px;
        color: $text-gray-dark;

        .title {
          color: black;
          display: block;
          font-size: 14px;
          font-weight: bold;
          margin: 0 0 5px;
        }

        .signature-area {
          border-bottom: 2px solid $text-gray-light;
          padding: 0 0 5px;
          text-align: center;
          margin: 0 0 15px;
          min-height: 70px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-flow: column wrap;

          img {
            display: inline-block;
            vertical-align: top;
            max-width: 80%;
          }
        }
      }

      @media (width >= 768px) {
        .col-24 {
          flex: 0 0 20%;
          max-width: 20%;
        }
      }

      p {
        margin: 0 0 5px;
      }
    }
  }
}

.cer-footer {
  margin: 0 auto;
  max-width: 1100px;
  border-top: 4px solid #e5e9ec;
  padding: 60px 15px;
  font:
    12px/17px "Open Sans",
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif;

  &::after {
    clear: both;
    content: "";
    display: block;
  }

  .certificate-logo {
    float: right;
    width: 129px;
    margin: 0 0 0 20px;
    position: relative;

    @include media-breakpoint-down(xs) {
      float: none;
      margin: 0 0 20px;
    }

    a {
      position: absolute;
      left: 0;
      top: 2px;
      width: 129px;
      height: 33px;
      filter: brightness(0) saturate(100%) invert(0%) sepia(5%) saturate(7500%)
        hue-rotate(261deg) brightness(98%) contrast(106%);
    }
  }

  .cer-footer-info {
    overflow: hidden;
  }

  a {
    color: #0079bc;
  }

  .copyright {
    display: block;
  }

  .links {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin: 0 15px 0 0;
    }
  }
}

.social-links {
  margin: 10px 0 20px 30px;
  list-style: none;
  padding: 0;
  float: right;

  @include media-breakpoint-down(xs) {
    float: none;
    margin: 0;
    text-align: center;
  }

  li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 15px;

    @include media-breakpoint-down(xs) {
      margin: 0 10px 15px;
    }
  }

  img {
    width: 25px;
  }
}

@media print {
  a {
    color: black !important;
  }

  .notifications.site-wide {
    display: none !important;
  }

  .no-print {
    display: none;
  }

  .full-width-print {
    max-width: 100%;
  }

  .certify-by-row {
    flex-wrap: nowrap;
  }

  @page {
    size: auto;
  }

  .certificate-page {
    background: white;

    .certificate-logo {
      max-width: 170px;
      margin: 0 auto 10px;
    }

    .certificate-wrapper {
      padding: 0;

      .certificate {
        border: 0;
        padding-top: 20px;

        .institute-logo {
          max-width: 140px;
          margin-bottom: 10px;
        }
      }

      .content-center {
        display: flex;
        min-height: 100vh;
        align-items: center;
      }

      .certificate-holder {
        .certify-by {
          padding-top: 0;
          padding-bottom: 7px;
          font-size: 10px;
          line-height: 13px;

          .title {
            font-size: 11px;
          }

          .signature-area {
            padding: 0 0 8px;
            margin: 0 0 8px;

            img {
              max-width: 60%;
              height: auto;
            }
          }
        }

        .success-text,
        .institute-text,
        .certify-text {
          font-size: 15px;
          line-height: 20px;
        }

        .certify-text {
          margin: 0 0 10px;
        }

        .award-text {
          font-size: 13px;
          line-height: 17px;
          font-weight: bold;
        }

        .program-degree-text {
          font-size: 13px;
          line-height: 17px;
          font-weight: bold;
        }

        .certify-name {
          font-size: 40px;
          line-height: 40px;
          margin: 0 0 15px;
        }

        .degree-text {
          width: 570px;
          font-size: 25px;
          line-height: 30px;
          display: inline-block;
          text-align: center;
        }

        .validation-link {
          font-size: 11px;
        }
      }
    }
  }

  @supports (-moz-appearance: meterbar) {
    .certificate-page {
      position: absolute !important;
      left: 0 !important;
      transform: scale(1.1);
    }
  }
}
