// sass-lint:disable mixins-before-declarations
@import "common";

.user-dashboard {
  padding: 40px 10px;

  .header {
    padding-right: 0;

    @include media-breakpoint-down(sm) {
      padding: 15px;
    }
  }

  h1 {
    padding-bottom: 30px;
    font-weight: 600;
  }

  h2 {
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 2rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
    }
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: $navy-blue;
    border-bottom: 1px solid $detail-grid-border-color;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  h5 {
    font-size: inherit;
    font-weight: inherit;
  }

  .link-button {
    font-weight: 400;
  }

  .empty-msg h2 {
    margin-bottom: 30px;
  }

  .alert {
    color: white;
    font-size: 24px;
    font-weight: 600;
    width: 100%;
    text-align: center;

    button.close {
      color: white;
      font-size: 56px;
      font-weight: 100;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 1;
    }
  }

  .alert-info {
    background-color: $light-blue;
  }

  .alert-danger {
    background-color: $primary;
  }
}

.text-ribbon,
.user-dashboard,
.collapse-toggle {
  font-size: 1.25rem;
  font-weight: 600;
}

.user-dashboard,
.collapse-toggle {
  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }
}

.program-enrollment,
.non-program-course-enrollments > .course-enrollment {
  margin-bottom: 15px;
}

.past-enrollments {
  margin-top: 40px;
}

.program-image-column,
.course-image-column {
  img {
    display: block;
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.certificate-link {
  @media (width <= 768px) {
    width: 100%;
  }

  > a {
    color: white;
    font-weight: 600;
    background-color: $denim-blue;
    padding-left: 1em;
    padding-right: 1em;
    float: left;

    @media (width <= 768px) {
      width: 100%;
      margin-bottom: 15px;
      text-align: center;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.modal-content-credentials {
  overflow: hidden;
  text-align: left;
  padding-bottom: 8px;
  background: $dc-background;

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 5px;
    width: 100%;
    background: linear-gradient(to right, #3dbdbf 0%, #f1ba46 100%);
  }
}

.digital-credential-dialog {
  h2 {
    border-bottom: 1px solid $tab-color;
    width: 100%;
    padding-bottom: 10px;
    font-weight: 500;
  }

  p {
    font-weight: 500;
  }
}

.digital-credential-link {
  float: left;
  margin-left: 30px;

  @media (width <= 768px) {
    margin-left: 0;
    width: 100%;
    text-align: center;
  }

  a {
    color: $link-blue;
    text-decoration: underline;
    background: transparent;

    &:hover {
      text-decoration: none;
    }
  }

  .digital-credential-store-button {
    display: none;

    @media (width <= 768px) {
      display: flex;
    }

    img {
      width: auto;
      height: 40px;
    }

    a {
      width: 100%;
      background: black;
      border-radius: 5px;
      margin-bottom: 15px;
      text-align: center;
      padding: 2px 20px;
    }
  }

  .learn-more-button {
    @media (width >= 768px) {
      float: right;
    }

    a {
      margin-top: 15px;
      border-radius: 5px;
      background: $light-gray;
      font-weight: 500;
      color: white;
      text-align: center;
      width: 100%;
      padding: 7px 20px;
      text-decoration: none;
    }

    &:hover {
      // underline is at different levels since we centered the text and checkmark vertically, so it looks strange
      text-decoration: underline;
    }
  }

  .download-digital-credential-button {
    display: none;

    @media (width <= 768px) {
      display: flex;
    }

    button {
      color: white;
      font-weight: 500;
      background: $denim-blue;
      padding: 7px 20px;
      border-radius: 5px;
      width: 100%;
      text-align: center;
      text-decoration: none;
      border: none;
      box-shadow: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.program-detail-column,
.non-program-course-enrollments .course-detail-column {
  padding: 15px;
  border: 1px solid #b4b4b4;
}

.course-detail-column {
  .status {
    text-align: left;

    @include media-breakpoint-up(lg) {
      text-align: right;
    }
  }

  .archived-course-link {
    a {
      color: $link-blue;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.program-enrollment {
  .program-courses {
    hr {
      margin-left: 10px;
      margin-right: 10px;
      border-color: $detail-grid-border-color;
    }

    .program-course {
      width: 100%;
    }

    .course-enrollment,
    .enrollment-code {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .program-detail-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .program-course {
      overflow: hidden;
    }
  }

  .collapse-toggle {
    padding: 0;
    margin: 0;
    border: none;
    background-color: white;
    color: $gray;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: $gray !important;
      background-color: inherit !important;
      outline: none !important;
      text-decoration: none;
      border: none;
      box-shadow: none;
    }
  }

  .expand-control {
    margin-left: auto;

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.view-receipt {
  text-decoration: underline;

  @media (width <= 768px) {
    margin-bottom: 15px;
  }
}

.program-enrollment.row,
.course-enrollment.row,
.enrollment-code.row {
  position: relative;

  .text-ribbon {
    position: absolute;
    width: 130px;
    height: 40px;
    top: 16px;
    right: 0;
    z-index: 10;

    &.course {
      .text {
        color: $primary;
      }
    }

    &.program {
      .text {
        color: $blue;
      }
    }

    .text {
      padding-left: 7px;
    }

    svg {
      width: 130px;
    }

    &.program svg {
      fill: $navy-blue;
    }

    &.course svg {
      fill: $primary;
    }
  }
}

.enrollment-code {
  margin-bottom: 15px;

  .course-detail-column {
    position: relative;
    padding: 15px;
    border: 1px solid $detail-grid-border-color;

    .expiration-date {
      color: $blue;
    }

    .btn-enroll {
      position: absolute;
      right: 15px;
      bottom: 15px;
      height: 38px;
      width: 220px;
      border-radius: 5px;
      background-color: $denim-blue;
      border-color: $denim-blue;

      .button-text {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 1.39px;
        line-height: 25px;
      }
    }
  }
}
