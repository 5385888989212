// sass-lint:disable mixins-before-declarations placeholder-in-extend

.learning-strategy-block {
  width: 100%;
  padding: 75px 0 0;
  background: url("#{$static-path}/images/enterprise/enterprise-page-dots-bg.png")
    repeat-x 0% 60% $alice-blue;
  background-size: contain;

  @include media-breakpoint-down(sm) {
    padding: 30px 5px 40px;
    background: $alice-blue;
  }

  .learning-strategy-heading {
    color: $black;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 24px;

    p {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
      font-size: 24px;
      text-align: initial;
      margin-bottom: 15px;
    }
  }

  .learning-strategy-subhead {
    color: $black;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 50px;

    p {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
      font-size: 18px;
      text-align: initial;
      font-weight: 500;
      line-height: 27px;
      margin-bottom: 0;
    }
  }
}

.learning-strategy-form {
  border-radius: 5px 5px 0 0;
  background: $white;
  margin: 0 45px;
  padding: 40px 35px;

  @include media-breakpoint-down(sm) {
    background: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .consent {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: $alice-blue;
    margin: 10px 15px 0;

    @include media-breakpoint-down(sm) {
      margin: 20px 0 0;
      background: $white;
    }

    .consent-msg {
      color: $black;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      margin: 0;
      padding: 15px;

      @include media-breakpoint-down(sm) {
        margin: 0;
        padding: 20px;
        text-align: initial;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

.hbspt-form {
  form {
    .hs-form-field:first-child {
      width: 100%;
    }

    .hs-form-field {
      display: inline-flex;
      flex-direction: column;
      align-items: initial;
      padding: 0 15px 30px;
      width: 50%;

      @include media-breakpoint-down(sm) {
        display: block;
        padding: 0;
        width: 100%;
        margin-bottom: 0;
      }

      label {
        color: rgb(0 0 0 / 60%);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @include media-breakpoint-down(sm) {
          padding-top: 20px;
        }
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      select {
        height: 50px;
        width: 100%;
        align-self: stretch;
        border-radius: 5px;
        border: 1px solid #dbe3eb;
        background: $white;
        box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%);
        padding: 10px 15px;
        color: $black;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .hs-error-msgs {
        padding: 0;
        margin: 0;
        list-style: none;

        @include media-breakpoint-down(sm) {
          left: 0;
          right: 0;
        }

        label {
          color: $primary;
          display: block;
        }
      }
    }

    .hs-form-field:nth-child(n + 2) {
      box-sizing: border-box;
    }

    .hs-submit {
      padding: 20px 155px;

      @include media-breakpoint-down(md) {
        padding: 0;
        margin: 20px 0 0;
      }

      input[type="submit"] {
        @extend .btn;
        @extend .btn-primary;

        border-radius: 5px;
        background: $primary;
        width: 500px;
        height: 55px;
        margin: 0 auto;
        display: block;
        position: relative;
        padding: 5px 10px;
        font-family: Rajdhani;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;

        @include media-breakpoint-down(sm) {
          width: 100%;
          height: auto;
          padding: 15px;
        }

        &:hover {
          background: $dark-blue;
          text-decoration: none;
          transition: all 0.2s ease-in;
        }
      }
    }

    .hs-recaptcha {
      align-items: center;
      width: 100%;
      padding: 10px 0;

      @include media-breakpoint-down(sm) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 20px 0 0;
      }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .hs_error_rollup {
      display: none;
    }
  }
}
