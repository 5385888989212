.notifications {
  position: relative;
  z-index: 1;

  // HACK: Using this rule to get around styling issues with <p> elements in the
  //       notification content (notification content in Wagtail is configured to be HTML,
  //       and Wagtail wraps content in a <p> tag by default when you use the editor).
  &.site-wide {
    p {
      margin: 0;
    }
  }

  .alert {
    margin-bottom: 0;

    &.alert-info,
    &.alert-danger {
      color: white;

      a.alert-link,
      .close {
        color: white;
      }

      a.alert-link {
        font-weight: inherit;
        text-decoration: underline;

        &:hover {
          font-weight: 700;
        }
      }

      .close {
        opacity: 1;
      }
    }

    &.alert-info {
      background-color: #126f9a;
    }

    &.alert-danger {
      background-color: $primary;
    }
  }

  // Django-rendered implementation
  .notification {
    background: $primary;
    text-align: center;
    color: white;

    p a {
      color: white;
      text-decoration: underline;
      background-color: transparent;
    }
  }

  .close-notification {
    text-decoration: none;
    color: white;
    float: right;
    margin-right: 10px;

    &::after {
      content: "cancel";
      font-family: "Material Icons";
      margin-left: 10px;
    }
  }
}
