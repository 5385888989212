// sass-lint:disable mixins-before-declarations

.tiles-block {
  padding: 32px 0;
  background: $tiles-background url("#{$static-path}/images/tiles-bg.png")
    repeat-x 0 100%;
  min-height: 200px;

  @include media-breakpoint-up(lg) {
    background-position: 0 100%;
  }

  .container {
    padding: 0;
  }
}

.tiles-list {
  letter-spacing: -0.32em;
  margin: 0;
  padding: 0 7px;
  list-style: none;
  text-align: center;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  li {
    letter-spacing: normal;
    background: $tile-background;
    border-radius: 5px;
    margin: 8px;
    padding: 32px 10px;

    @include media-breakpoint-up(lg) {
      display: inline-block;
      vertical-align: top;
      width: calc(20% - 16px);
      min-height: 215px;
      padding: 32px 10px 10px;
    }
  }

  .title {
    display: block;
    font-size: 18px;
    font-weight: bold;
    color: $primary;
    text-align: center;
    position: relative;
    padding: 0 0 5px;
    line-height: 22px;
    text-transform: uppercase;
    margin: 0 0 15px;

    &::after {
      position: absolute;
      left: 50%;
      top: 100%;
      border-bottom: 1px solid $primary;
      width: 100px;
      content: "";
      margin: 0 0 0 -50px;
    }
  }

  .text {
    display: block;
    font-size: 40px;
    line-height: 50px;
    color: $light-gray;
    font-weight: 600;
    text-align: center;

    @include media-breakpoint-up(lg) {
      font-size: 30px;
      line-height: 50px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 40px;
      line-height: 50px;
    }
  }

  .link {
    display: block;
    font-size: 18px;
    line-height: 22px;
    color: $light-gray;
    font-weight: 500;
    text-align: center;

    a {
      color: $primary;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.popover {
  font-family: Rajdhani, "Open Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  font-weight: 600;
  max-width: 100% !important;

  .popover-header {
    background-color: white;
    color: $primary;
    font-size: larger;
    text-align: center;
    border-bottom: 1px solid $primary;
  }

  .popover-body {
    text-align: center;

    .date-link {
      font-weight: 600;
      font-size: large;
      color: $blue;
    }
  }
}
