// sass-lint:disable mixins-before-declarations
.webinars {
  .webinar-sub-banner {
    background:
      linear-gradient(rgb(0 0 0 / 30%), rgb(0 0 0 / 50%)),
      url("#{$static-path}/images/webinars/webinar-header-banner.jpg") no-repeat
        50% 50%;
    background-size: cover;
  }

  h1 {
    text-align: center;
  }

  .webinar-category {
    text-align: center;
    color: $primary;
    margin: 60px 0;
  }

  .no-available-webinars {
    font-size: 1.75rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 473px;
    text-align: center;
  }

  .webinars-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto 84px;
    max-width: 1150px;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    .webinar {
      position: relative;
      flex: 1 0 33%;
      max-width: 350px;
      min-height: 473px;
      margin-bottom: 36px;
      margin-left: 15px;
      margin-right: 15px;
      padding: 10px 9px 0 11px;
      background-color: #fff;
      box-sizing: border-box;
      box-shadow: 2px 2px 8px 4px rgb(0 0 0 / 6%);
      border-radius: 4px;

      @include media-breakpoint-down(lg) {
        max-width: 300px;
      }

      @include media-breakpoint-down(md) {
        max-width: 310px;
      }

      .webinar-holder {
        img {
          margin-bottom: 12px;
          max-width: 330px;
          height: 200px;

          @include media-breakpoint-down(lg) {
            max-width: 280px;
          }

          @include media-breakpoint-down(md) {
            max-width: 290px;
          }
        }

        .time,
        .description {
          color: #000;
          font-family: Rajdhani;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: -0.52px;
          line-height: 20px;
        }

        .title,
        .description {
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3; // sass-lint:disable-line no-vendor-prefixes
          -webkit-box-orient: vertical; // sass-lint:disable-line no-vendor-prefixes
          display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
        }

        .title {
          color: $primary;
          font-size: 22px;
          font-weight: bold;
          letter-spacing: -0.45px;
          line-height: 25px;
          margin-bottom: 19px;
        }

        .read-more {
          position: absolute;
          bottom: 20px;
          background: $primary;
          border-radius: 3px;
          padding: 5px 5px 5px 10px;
          font-size: 20px;
          line-height: 24px;
          display: inline-block;
          vertical-align: top;
          font-weight: 800;
          text-transform: uppercase;
          color: white;

          &::after {
            content: "arrow_circle_up";
            display: inline-block;
            font-family: "Material Icons";
            line-height: 24px;
            margin: 0 0 0 10px;
            text-transform: none;
            transform: rotate(90deg);
            vertical-align: top;
          }
        }
      }
    }
  }
}

.webinar-resource-banner {
  background-size: cover;

  h1 {
    text-align: center;
  }

  h2 {
    color: white;
  }
}

.webinar-row {
  padding-top: 30px;
}

.back-to-webinars {
  float: right;
}
