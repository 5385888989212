// sass-lint:disable mixins-before-declarations

html {
  scroll-behavior: smooth;
}

.resource-banner {
  width: 100%;
  padding: 75px 0;
  background: rgb(53 53 53 / 97%)
    url("#{$static-path}/images/resource-page-banner-bg.png") repeat-x 0 0;
  min-height: 200px;
  margin-bottom: 20px;

  .container {
    max-width: 880px;
    text-align: center;
  }

  h1 {
    background: rgba($primary, 0.8);
    color: white;
    padding: 15px;
    line-height: 50px;
    font-weight: 500;
    margin: 0 0 15px;

    @include media-breakpoint-up(md) {
      padding: 15px 30px;
      font-size: 48px;
      line-height: 54px;
      display: inline-block;
    }
  }

  h2 {
    color: white;
    line-height: 32px;
    font-weight: 600;
    margin: 0 0 15px;
  }
}

.resource-container {
  h2 {
    color: $blue;
    margin-top: 50px;
    font-weight: 600;
  }

  .resource-list {
    list-style: none;
    margin-top: 50px;
    padding-left: 0;

    a {
      color: black;
      font-weight: 600;
      text-decoration: none;

      &:hover {
        color: $blue;
      }
    }

    .item {
      border-top: 1px solid;
      padding-bottom: 10px;
      padding-top: 10px;

      &:first-child {
        border-top: none;
      }
    }
  }

  .back-to-top {
    text-decoration: none;
    display: block;
    margin-bottom: 10px;

    &::after {
      font-size: 24px;
      font-family: "Material Icons";
      content: "arrow_circle_up";
      margin-left: 5px;
      vertical-align: middle;
    }
  }

  .read-more {
    text-decoration: none;
    display: block;

    &::after {
      content: "arrow_circle_up";
      font-size: 24px;
      display: inline-block;
      font-family: "Material Icons";
      margin: 0 0 10px 5px;
      transform: rotate(90deg);
      vertical-align: top;
    }
  }

  .resource-video {
    margin-left: 100px;
  }
}
