.auth-page {
  max-width: 600px;
  padding-top: 15px;
  padding-bottom: 35px;

  &.registration-page {
    max-width: 800px;
  }

  h1 {
    @include media-breakpoint-down(xs) {
      font-size: 28px;
    }

    letter-spacing: 0.06em;
  }

  b {
    font-weight: 600;
  }

  h4 {
    @include media-breakpoint-down(xs) {
      font-size: 16px;
    }
  }

  .confirm-sent-page {
    font-size: 16px;
    letter-spacing: 1.05px;
    line-height: 20px;
    padding: 49px 95px 40px;
    position: relative;
    box-shadow:
      inset 0 1px 3px 0 rgb(0 0 0 / 50%),
      -4px 3px 17px -4px rgb(0 0 0 / 25%);
    background-color: $very-light-gray;

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      content: "";
      height: 8px;
      background: linear-gradient(
        to right,
        rgb(61 189 191 / 100%) 0%,
        rgb(241 186 70 / 100%) 100%
      );
    }

    .email {
      color: $link-blue;
      font-weight: 600;
    }

    a {
      color: $link-blue;
    }

    div:first-of-type {
      font-weight: 600;
    }

    .font-weight-600 {
      font-weight: 600;
    }

    .contact-support {
      padding-top: 20px;
      line-height: 21px;
    }

    ol {
      padding-top: 5px;
      padding-left: 18px;
      letter-spacing: 0.55px;
      line-height: 21px;
      margin-bottom: 0;

      li {
        margin-bottom: 4px;
      }
    }

    .browse-courses {
      padding-top: 41px;
    }
  }

  &.account-settings-page {
    max-width: 800px;

    .email-section {
      margin: 30px 0 0;
      border: 1px solid $tab-color;
      border-width: 1px 0 0;
      padding: 23px 0 0;

      h4 {
        font-size: 23px;
        line-height: 28px;
        font-weight: 600;
        margin-top: 5px;
        padding-bottom: 10px;
      }
    }
  }
}

.auth-card {
  @include media-breakpoint-down(sm) {
    padding: 20px;
  }

  @include media-breakpoint-down(xs) {
    padding: 15px 0;
  }

  background-color: $very-light-gray;
  border-width: 7px 0 0;
  border-style: solid;
  border-image-source: linear-gradient(90deg, #43bebc, #ebbb47);
  border-image-slice: 1;
  padding: 40px;
}

.auth-form {
  .form-control {
    font-size: inherit;
    font-weight: inherit;
  }

  .form-error {
    font-size: 1rem;
    font-weight: 400;
  }

  .errored {
    border: 2px solid $red;
  }

  label {
    font-weight: 600;
  }

  .submit-row {
    margin-top: 25px;
  }
}

.registration-page .auth-form {
  .row-inner {
    margin-top: 20px;
  }

  .col {
    align-self: flex-start;
  }
}

.additional-street {
  cursor: pointer;
  color: $light-blue;
  font-style: italic;
  border: none;
  background: none;
}

.add-vat-id {
  color: $light-blue;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  border: none;
  background: none;
  margin-bottom: 15px;
  padding-left: 0;
}

.register-error-icon {
  margin: 0 auto 15px;
  background-image: url("/static/images/icon-warning.svg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}
