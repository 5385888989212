// sass-lint:disable mixins-before-declarations

.who-should-enroll-block {
  padding: 150px 0 50px;
  background: $darker-grey-bg url("/static/images/who-should-enroll-bg.png")
    repeat-x 0 0;
  min-height: 400px;
  overflow: hidden;
  position: relative;
  margin: -50px 0 0;
  color: white;
  text-align: center;

  @include media-breakpoint-up(lg) {
    background-position: 0 100%;
    text-align: left;
  }

  .stick-left {
    left: 0;
  }

  &::after {
    position: absolute;
    left: 50%;
    top: -350px;
    content: "";
    background: white;
    width: 1200px;
    height: 400px;
    border-radius: 50%;
    margin: 0 0 0 -600px;

    @include media-breakpoint-up(md) {
      margin: 0 0 0 -1500px;
      width: 3000px;
    }
  }

  [class^="col-"],
  [class*=" col-"],
  .container {
    @include media-breakpoint-up(lg) {
      position: static;
      min-height: 505px;
    }
  }

  .img-holder {
    padding: 40px 0 0;

    @include media-breakpoint-up(lg) {
      position: absolute;
      right: 0;
      top: 150px;
      width: calc(50% - 40px);
      padding: 0;
    }

    img {
      display: block;
      width: 100%;
      height: auto;

      @include media-breakpoint-up(lg) {
        height: 505px;
        object-fit: cover;
      }
    }
  }

  h1 {
    color: white;
    margin: 0 0 10px;

    @include media-breakpoint-up(lg) {
      margin: 0 0 40px;
    }
  }

  .item-list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      font-size: 20px;
      line-height: 28px;
      padding: 20px 0;
      font-weight: 500;
      border-top: 1px solid rgba(white, 0.2); /* stylelint-disable-line */
      text-align: left;

      &:first-child {
        border: none;
      }
    }
  }
}
