// sass-lint:disable mixins-before-declarations
.sub-nav-bar {
  background: $gray-bg;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 13px rgb(0 0 0 / 50%);

  .navbar-nav > li > a {
    color: black !important;

    &.active {
      color: $blue !important;
    }
  }

  &.navbar-expand-md {
    .navbar-collapse {
      @include media-breakpoint-up(md) {
        flex-grow: initial;
      }
    }
  }

  .navbar-toggler {
    border: unset;
    color: $blue !important;
    width: calc(100% - 160px);

    span.fa-chevron-down {
      display: none;
    }

    span.fa-chevron-up {
      display: block;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  .navbar-toggler.collapsed {
    span.fa-chevron-down {
      display: block;
    }

    span.fa-chevron-up {
      display: none;
    }
  }

  .sub-nav-enroll-button-block {
    position: relative;

    @include media-breakpoint-up(md) {
      order: 2;
    }

    .enroll-button {
      display: inline-flex;
      margin: 0;
      border-radius: 5px;
      background-color: $primary;
      color: white !important;
      padding: 15px 25px;
      text-transform: uppercase;
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      margin-left: 20px;

      &.enrolled {
        border: 4px solid $primary;
        background-color: white;
        color: $primary !important;
      }

      &:hover {
        // underline is at different levels since we centered the text and checkmark vertically, so it looks strange
        text-decoration: none;
      }
    }

    .enroll-dropdown {
      .dropdown-toggle::after {
        display: none;
      }

      .login-popup {
        max-width: 350px;
        margin-left: 20px;
        margin-right: 20px;
        padding: 20px;
        font-weight: 600;
        font-size: 20px;
        min-width: 320px;

        .close-btn {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          margin: 20px 7px 0 0;
          font-size: 72px;
          font-weight: 300;
          background-color: white !important;
          color: black;
          min-width: unset;
          height: unset;
          cursor: pointer;
        }

        h4 {
          font-weight: 700;
          font-size: 22px;
        }

        .popup-buttons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 20px;

          .link-button {
            border: 2px solid $light-blue;
            border-radius: 10px;
            font-weight: 600;
            font-size: 24px;

            &.sign-in-link {
              color: white;
              background-color: $light-blue;
            }

            &.create-account-link {
              color: black;
            }
          }
        }
      }

      .triangle {
        width: 15px;
        height: 15px;
        position: absolute;
        top: -7px;
        background: white;
        transform: rotate(45deg);
      }

      .dropdown-menu {
        .triangle {
          right: 24px;
        }
      }
    }

    .action-button {
      font-size: x-large;
      font-weight: 400;
    }
  }

  .sub-nav-signup-button-block {
    position: relative;

    @include media-breakpoint-up(md) {
      order: 3;
    }

    .keep-me-updated {
      display: inline-flex;
      margin: 0;
      border-radius: 5px;
      padding: 15px 25px;
      text-transform: uppercase;
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      margin-left: 20px;
      background: $white;
      border: 1px solid $primary;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
        background: $close-hover-background;
      }
    }
  }
}

.modal-backdrop {
  // If set the z-index to 'a value > 1019' or it's default, it probably gets overlapped by another stacking context.
  z-index: 1019;
}

a.active-block {
  background: $gray-bg;
  padding: 5px 0;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  display: block;
  color: black;
  text-decoration: none;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    display: none;
  }

  &::after {
    display: inline-block;
    vertical-align: middle;
    content: "";
    margin: 0 0 0 10px;
    width: 0;
    height: 0;
    transform: rotate(0deg);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid black;
  }

  &.collapsed {
    &::after {
      transform: rotate(180deg);
    }
  }
}
