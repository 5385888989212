// sass-lint:disable mixins-before-declarations

.learning-journey-block {
  padding-top: 70px;
  background: url("#{$static-path}/images/enterprise/enterprise-page-dots-bg.png")
    repeat-x 0% 100% $alice-blue;
  position: relative;

  @include media-breakpoint-down(sm) {
    background: none;
    padding: 30px 5px 0;
  }

  .learning-journey-heading {
    color: $black;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 50px;

    @include media-breakpoint-down(sm) {
      text-align: initial;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 25px;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.learning-journey-container {
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  @include media-breakpoint-only(lg) {
    justify-content: space-around;
  }

  .learning-journey-content {
    display: flex;
    flex-direction: column;
    width: 400px;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-only(md) {
      width: 285px;
    }

    @include media-breakpoint-only(lg) {
      width: 300px;
    }

    ul {
      padding: 0;
      margin-bottom: 15px;
    }

    li {
      list-style-type: none;
      border-radius: 5px;
      background: $white;
      padding: 12px 18px;
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%);
      color: $black;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 15px;

      @include media-breakpoint-only(lg) {
        font-size: 17px;
      }
    }

    .learning-journey-description {
      color: $black;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 27px;
      width: 449px;
      margin-bottom: 0;
      -webkit-line-clamp: 3; // sass-lint:disable-line no-vendor-prefixes

      @include media-breakpoint-down(md) {
        width: 100%;
        font-size: 16px;
        line-height: 25px;
      }

      @include media-breakpoint-only(lg) {
        line-height: 25px;
        width: 300px;
      }

      p {
        margin-bottom: 0;
      }
    }

    a {
      border-radius: 5px;
      background: $primary;
      color: $white;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      text-transform: uppercase;
      margin-top: 40px;
      width: 80%;
      display: inline-flex;
      padding: 12px 20px;
      flex-direction: column;
      align-items: center;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin: 35px 0 30px;
      }

      @include media-breakpoint-only(md) {
        padding: 8px 12px;
      }

      @include media-breakpoint-only(lg) {
        padding: 10px 15px;
      }

      &:hover {
        background: $dark-blue;
        color: $white;
        text-decoration: none;
        transition: all 0.2s ease-in;
      }
    }
  }

  img {
    width: 560px;
    height: 618px;
    position: relative;
    z-index: 1;
    max-width: 100%;
    margin-bottom: -1px;

    @include media-breakpoint-down(sm) {
      height: 386px;
    }

    @include media-breakpoint-only(md) {
      width: 350px;
      height: 570px;
    }

    @include media-breakpoint-only(lg) {
      width: 450px;
    }
  }
}
