// sass-lint:disable mixins-before-declarations

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex !important;
  margin-left: auto;
  margin-right: auto;
}

.slick-track::before,
.slick-track::after {
  display: table;
  content: "";
}

.slick-track::after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: inherit !important;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev,
.slick-next {
  position: absolute;
  left: 0;
  z-index: 99;
  top: 50%;
  font-size: 0;
  line-height: 0;
  border: none;
  background: white;
  color: black;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  outline: none;
  box-shadow: 5px 5px 10px 0 rgb(0 0 0 / 10%);
  margin: -75px 0 0;
  cursor: pointer;
  padding: 0;

  @include media-breakpoint-up(md) {
    left: -25px;
    width: 50px;
    height: 50px;
    padding: 5px 0;
  }

  @include media-breakpoint-up(lg) {
    width: 50px;
    height: 50px;
    padding: 15px 0;
  }

  @media (width >= 1275px) {
    left: -65px;
    width: 70px;
    height: 70px;
  }

  @media (width >= 1450px) {
    left: -135px;
  }

  &:hover {
    color: white;
    background: black;
  }

  &:focus {
    outline: none;
  }

  &::after {
    font-size: 30px;
    line-height: 30px;
    font-family: "Material Icons";
    content: "chevron_left";

    @include media-breakpoint-up(md) {
      font-size: 34px;
      line-height: 20px;
    }

    @media (width >= 1275px) {
      font-size: 50px;
      line-height: 40px;
    }
  }
}

.slick-next {
  left: auto;
  right: 0;

  @include media-breakpoint-up(md) {
    right: -25px;
  }

  &::after {
    content: "chevron_right";
  }

  @media (width >= 1275px) {
    right: -65px;
  }

  @media (width >= 1450px) {
    right: -135px;
  }
}

.slick-dots {
  margin: 0;
  padding: 57px 0 0;
  list-style: none;
  letter-spacing: -0.32em;
  text-align: center;

  li {
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 10px;

    button {
      text-indent: -9999px;
      overflow: hidden;
      border: none;
      background: #8a8b8c;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      outline: none;
      cursor: pointer;

      &:hover {
        background: white;
      }
    }

    &.slick-active {
      button {
        background: white;
      }
    }
  }
}
