// sass-lint:disable mixins-before-declarations

.sub-banner {
  width: 100%;
  padding: 75px 0;
  background:
    linear-gradient(rgb(0 0 0 / 30%), rgb(0 0 0 / 50%)),
    url("#{$static-path}/images/header-banner.jpg") no-repeat 50% 50%;
  min-height: 200px;
  background-size: cover;

  .container {
    text-align: center;
  }

  h1 {
    color: white;
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      font-size: 48px;
      line-height: 54px;
    }
  }
}
