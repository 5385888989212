// sass-lint:disable mixins-before-declarations
$label-color: #8a8b8c;

.receipt-wrapper {
  padding: 20px 15px;
  margin: 0 auto;
  max-width: 1018px;
  font:
    500 18px/23px Rajdhani,
    "Times New Roman",
    serif;
  color: black;

  @media only screen and (width <= 992px) {
    font:
      500 16px/21px Rajdhani,
      "Times New Roman",
      serif;
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: $link-blue;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  dl {
    overflow: hidden;
    margin: 0 0 10px;

    dt {
      float: left;
      width: 160px;
      font-weight: 600;

      @media only screen and (width <= 767px) {
        width: 120px;
      }
    }

    dd {
      overflow: hidden;
      padding: 0 0 0 15px;
      margin: 0;
      word-break: break-all;
    }
  }

  h2 {
    font:
      600 40px/51px Rajdhani,
      "Times New Roman",
      serif;
    margin: 0 0 30px;
    color: black;

    @media only screen and (width <= 767px) {
      font:
        600 30px/41px Rajdhani,
        "Times New Roman",
        serif;
      margin: 0 0 10px;
    }
  }
}

.receipt-row {
  overflow: hidden;
  margin: 0 -15px;
  position: relative;

  &.p-b-80 {
    padding-bottom: 65px;

    @media only screen and (width <= 767px) {
      padding-bottom: 0;
    }
  }

  .print-btn {
    position: absolute;
    right: 15px;
    top: 0;
  }

  .receipt-col {
    float: left;
    width: 50%;
    padding: 0 15px;

    @media only screen and (width <= 767px) {
      float: none;
      width: 100%;
      padding-bottom: 40px;
    }

    &.p-t-50 {
      padding-top: 50px;

      @media only screen and (width <= 767px) {
        padding-top: 0;
      }
    }
  }

  h3 {
    font:
      600 20px/26px Rajdhani,
      "Times New Roman",
      serif;
    margin: 0 -15px 20px;
    border: none;
    padding: 5px 15px;
    color: black;
    background: $very-light-gray;

    @media only screen and (width <= 992px) {
      font:
        600 18px/24px Rajdhani,
        "Times New Roman",
        serif;
    }
  }
}

.receipt-logo {
  width: 125px;
  height: 35px;
  margin: 0 0 12px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.footnote-signature {
  padding-top: 10px;
  text-align: center;

  small {
    display: block;
    font-size: 16px;
    font-weight: 400;
  }
}

.receipt-mit-info {
  max-width: 226px;
  font-size: 16px;
  line-height: 21px;

  p {
    margin: 0;
  }
}

.receipt-table-holder {
  overflow-x: auto;
  margin: 0 -15px;
  padding: 0 0 15px;

  h3 {
    font:
      600 20px/26px Rajdhani,
      "Times New Roman",
      serif;
    margin: 0;
    padding: 5px 15px;
    color: black;
    background: $very-light-gray;
    display: none;
    border: none;

    @media only screen and (width <= 767px) {
      display: block;
    }
  }

  .receipt-table {
    width: 100%;
    border-spacing: 0;

    thead {
      @media only screen and (width <= 767px) {
        display: none;
      }
    }

    th {
      padding: 5px 15px;
      text-align: left;
      color: black;
      background: $very-light-gray;
      white-space: nowrap;
      font:
        600 20px/26px Rajdhani,
        "Times New Roman",
        serif;

      @media only screen and (width <= 992px) {
        font:
          600 18px/24px Rajdhani,
          "Times New Roman",
          serif;
      }
    }

    td {
      padding: 20px 15px 0;
      vertical-align: top;

      @media only screen and (width <= 767px) {
        display: block;
        width: 100%;
      }

      p {
        display: none;
        color: black;
        white-space: nowrap;
        padding: 0 10px 0 0;
        font:
          600 18px/24px Rajdhani,
          "Times New Roman",
          serif;
        float: left;
        width: 135px;

        @media only screen and (width <= 767px) {
          display: block;
        }
      }

      div {
        overflow: hidden;
      }
    }
  }

  .receipt-hsn {
    padding: 5px 15px;
    text-align: left;
    color: black;
  }
}

@media print {
  .print-btn,
  .print-row,
  .footer,
  .header-holder,
  .notifications {
    display: none !important;
  }

  iframe[title="Message from company"],
  iframe[title="Close message"] {
    display: none !important;
  }

  .receipt-wrapper {
    padding: 0 15px;
    font:
      500 16px/21px Rajdhani,
      "Times New Roman",
      serif;

    .footnote-signature {
      padding-top: 300px;
    }
  }
}
