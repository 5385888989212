// sass-lint:disable mixins-before-declarations
@import "common";

.blog-page {
  background: #f0f5f7;
  padding-bottom: 150px;

  .body {
    .blog-header {
      display: flex;
      width: 1140px;
      padding: 50px 10px;
      justify-content: space-between;
      align-items: center;
      margin: auto;

      .heading-container {
        color: $tile-background;
        font-style: normal;
        line-height: 200%;

        .title {
          font-size: 48px;
          font-weight: 700;
          display: flex;
          padding: 5px;
          margin: 0 0 3px;
          align-items: flex-start;
          background: $dark-blue;
          width: fit-content;

          @include media-breakpoint-down(md) {
            display: inline-flex;
          }
        }

        .heading {
          font-size: 26px;
          font-weight: 400;
          display: flex;
          padding: 5px;
          margin-bottom: 0;
          align-items: flex-start;
          line-height: 100%;
          background: $dark-blue;

          @include media-breakpoint-down(md) {
            justify-content: center;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        max-width: 80%;
      }

      @include media-breakpoint-down(md) {
        display: inherit;
        padding: 30px 10px;
        text-align: center;
      }
    }

    .most-recent-posts {
      .recent-posts-heading {
        color: $tile-background;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-align: center;
        margin-bottom: 30px;
      }

      .recent-posts {
        .container {
          margin-bottom: 80px;

          .posts-slider {
            position: relative;
            margin: 0 -15px;

            .slide {
              float: left;
              width: 33.333%;
              margin: 10px;
            }

            .slick-prev,
            .slick-next {
              margin: -3% 0 0 !important;
            }

            img {
              @include media-breakpoint-down(lg) {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .all-posts {
      .more-posts-heading {
        color: $dark-blue;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-align: center;
        margin-bottom: 40px;
      }

      .all-posts-container {
        .posts-list {
          display: flex;
          width: 1140px;
          align-items: flex-start;
          align-content: flex-start;

          // sass-lint:disable no-misspelled-properties
          gap: 30px;
          flex-wrap: wrap;
          margin-left: auto;
          margin-right: auto;

          @include media-breakpoint-down(lg) {
            width: 100%;
            justify-content: center;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    background-size: 100% 350px;
  }

  @include media-breakpoint-down(sm) {
    background-size: 100% 380px;
  }
}

.subscribe {
  display: flex;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: $primary;
  color: $tile-background;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;

  &:hover {
    background: $dark-blue;
    text-decoration-line: none;
    color: $tile-background;
  }

  @include media-breakpoint-down(md) {
    display: inline-flex;
    margin-top: 10px;
  }
}

.post {
  display: flex;
  width: 360px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 5px;
  background: $tile-background;
  box-shadow: 0 2px 3px 0 rgb(3 21 45 / 10%);
  border: 2px solid $tile-background;
  cursor: pointer;

  &:hover {
    border: 2px solid $primary;

    .title {
      color: $primary;
    }

    .card-top {
      img {
        filter: brightness(100%);
      }
    }
  }

  @include media-breakpoint-down(lg) {
    width: 310px;
  }

  @include media-breakpoint-down(md) {
    width: 330px;
  }

  @include media-breakpoint-down(sm) {
    width: 360px;
  }

  .card-top {
    position: relative;
    text-align: center;

    img {
      margin-bottom: 12px;
      width: 320px;
      height: 200px;
      border-radius: 5px;
      background: $dark-blue;
      box-shadow: 0 2px 3px 0 rgb(3 21 45 / 10%);
      filter: brightness(50%);
      transition: all 1s ease;

      @include media-breakpoint-down(lg) {
        width: 270px;
      }

      @include media-breakpoint-down(md) {
        width: 290px;
      }

      @include media-breakpoint-down(sm) {
        width: 320px;
      }
    }

    .post-tags {
      display: flex;
      position: absolute;
      left: 0;
      top: 173px;
      gap: 3px;

      .tag {
        display: flex;
        padding: 5px;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        background: $dark-blue;
        color: $tile-background;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        text-transform: uppercase;
      }
    }
  }

  .title,
  .description {
    overflow: hidden;
    text-overflow: ellipsis;

    // sass-lint:disable no-vendor-prefixes
    -webkit-box-orient: vertical;
    display: -webkit-box; // sass-lint:disable no-vendor-prefixes
    align-self: stretch;
    color: $dark-blue;
    font-style: normal;
  }

  .title {
    font-size: 19px;
    font-weight: 700;
    line-height: 120%;
    -webkit-line-clamp: 2; // sass-lint:disable no-vendor-prefixes
    margin-bottom: 5px;

    &:hover {
      text-decoration-line: none;
      color: $primary;
    }
  }

  .description {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    min-height: 110px;
    -webkit-line-clamp: 5; // sass-lint:disable no-vendor-prefixes
  }

  .card-bottom {
    display: flex;
    padding-top: 20px;
    align-items: flex-start;
    align-self: stretch;
    min-height: 50px;

    .author {
      flex: 1 0 0;
      color: #7e868e;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      text-transform: uppercase;
      padding-top: 7px;
    }

    .read-more {
      display: flex;
      padding: 6px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 3px;
      border: 1px solid $gray-bg;
      background: $tile-background;
      color: $primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      text-transform: uppercase;

      &:hover {
        text-decoration-line: none !important;
        background: $primary !important;
        color: $tile-background !important;
      }
    }

    @include media-breakpoint-down(lg) {
      min-height: 80px;
    }

    @include media-breakpoint-down(md) {
      min-height: 50px;
    }
  }
}
