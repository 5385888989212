// sass-lint:disable mixins-before-declarations placeholder-in-extend
.posted-block {
  width: 100%;
  padding: 75px 0;
  background: url("#{$static-path}/images/bg-contact.jpg") repeat-x 50% 50%;
  min-height: 200px;
  background-size: cover;
  position: relative;

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    content: "";
    height: 10px;
    background: linear-gradient(
      to right,
      rgb(61 189 191 / 100%) 0%,
      rgb(241 186 70 / 100%) 100%
    );
  }

  h1 {
    color: white;
    margin: 0 0 50px;
    text-transform: uppercase;
    text-align: center;
  }

  form {
    margin: 0 auto;
    max-width: 880px;

    fieldset {
      max-width: 100%;
      position: relative;

      &.form-columns-2 {
        .input {
          margin: 0;
        }

        .hs-form-field {
          padding: 0 15px;
          position: relative;

          @include media-breakpoint-down(sm) {
            padding: 0;
            width: 100%;
          }
        }
      }

      &.form-columns-1 {
        /* stylelint-disable-next-line selector-class-pattern */
        .hs_email {
          padding: 0 15px;
          width: 100%;

          @include media-breakpoint-down(sm) {
            padding: 0;
          }
        }

        .input {
          margin: 0;
        }

        .hs-input {
          width: 100%;
        }

        .legal-consent-container {
          padding: 0 15px;
          color: white;

          @include media-breakpoint-down(sm) {
            padding: 0;
          }
        }
      }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .hs_error_rollup {
      display: none;
    }

    label {
      color: white;
      display: none;
    }

    input[type="text"],
    input[type="email"] {
      width: 100% !important;
      margin: 0 0 30px;
      font-family: inherit;
      font-weight: 500;
      border-radius: 5px;
      border: none;
      font-size: 18px;
      line-height: 26px;
      height: 46px;
      padding: 10px 15px;
      outline: none;
    }

    input[type="submit"] {
      @extend .btn;
      @extend .btn-primary;

      width: 230px;
      height: 70px;
      font-size: x-large;
      font-weight: 400;
      margin: 0 auto 20px;
      display: block;
      position: relative;
      top: 20px;
      padding: 5px 10px;
    }
  }

  .hs-error-msgs {
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;

    @include media-breakpoint-down(sm) {
      left: 0;
      right: 0;
    }

    label {
      color: $primary;
      display: block;
      line-height: 25px;
      margin: 0;
    }
  }
}

.submitted-message {
  margin-left: 200px;
  color: white;
  font-family: Rajdhani;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 4.95px;
  line-height: 30px;
  text-align: center;
  width: 675px;

  p:nth-child(2) {
    color: white;
  }

  @include media-breakpoint-down(lg) {
    margin: 0;
    width: auto;
  }
}
