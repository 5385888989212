.profile-image {
  border-radius: 100%;
  background-color: $cornflower-blue;
  color: $white;
  width: 34px;
  height: 34px;
  overflow: hidden;
}

.collapsed.dropdown-toggle::after {
  border-top: 0.3em solid transparent;
  border-left: 0.3em solid;
  border-bottom: 0.3em solid transparent;
  border-right: 0;
  vertical-align: unset;
}

.dropdown-menu {
  background-color: $white-smoke;
  box-shadow: -5px 5px 10px grey;
  border-color: $white-smoke;
  border-radius: 5px;
  right: 0;
  left: unset;

  .dropdown-divider {
    border-top: 1px dashed $black;
    margin: 16px 25px;
  }

  .dropdown-item {
    display: flex;
    margin-top: 10px;
    align-items: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    color: $black;
    line-height: 14px;

    .dropdown-icon {
      margin-right: 20px;
    }

    &:active {
      background-color: $soft-light-gray;
    }
  }
}

.user-menu.dropdown,
.catalog-menu.dropdown {
  padding-left: 15px;
  padding-right: 15px;

  .dropdown-toggle {
    max-width: 100%;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.icon-profile {
  background: url("#{$static-path}/images/icon-user.png") left no-repeat;
  height: 25px;
  width: 25px;
}

.icon-settings {
  background: url("#{$static-path}/images/icon-settings.png") left no-repeat;
  height: 25px;
  width: 25px;
}

.icon-dashboard {
  background: url("#{$static-path}/images/icon-dashboard.png") left no-repeat;
  height: 25px;
  width: 25px;
}

.icon-logout {
  background: url("#{$static-path}/images/icon-logout.png") left no-repeat;
  height: 25px;
  width: 25px;
}
