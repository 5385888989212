.faqs-block {
  background: $gray-bg;
  padding: 90px 0;

  .faq-head {
    text-align: center;
    margin: 0 auto;
    max-width: 884px;
  }

  h1 {
    color: $primary;
    margin: 0 0 15px;
  }

  h3 {
    margin: 0 0 50px;

    a {
      color: $blue;
    }
  }

  .readmore {
    font-size: 18px;
    line-height: 24px;
    color: $blue;
    display: inline-block;
    vertical-align: top;
    font-weight: 600;

    &::after {
      content: "arrow_circle_up";
      font-family: "Material Icons";
      margin-left: 20px;
      text-transform: none;
      transform: rotate(90deg);
    }
  }

  #accordion {
    padding: 0;

    li {
      padding: 20px 0;
      border-width: 0 0 1px;
      background: none;
    }

    p {
      margin: 20px 0 0;
    }

    .card-header {
      background: none;
      padding: 0;
      border: none;
    }

    .btn-link {
      text-align: left;
      width: 100%;
      font-size: 26px;
      line-height: 26px;
      padding: 0 50px 0 0;
      font-weight: 600;
      cursor: pointer;
      text-decoration: none;
      position: relative;

      &::after {
        position: absolute;
        right: 20px;
        top: 40%;
        content: "";
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid $primary;
      }

      &.collapsed {
        &::after {
          transform: rotate(180deg);
        }
      }

      &:hover {
        color: $blue;
      }
    }
  }
}
