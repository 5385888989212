// sass-lint:disable mixins-before-declarations placeholder-in-extend
#header {
  background-color: white;
  position: relative;
  z-index: 1;
  height: 98px;
}

.header-block {
  background-size: cover;
  background-position: 50% 50%;
  position: relative;

  .background-video {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(0%);

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.header-container {
  color: white;
  padding: 85px 15px;

  .program-position {
    font-size: 20px;
    line-height: 22px;
    margin: 0 15px 10px;
    font-weight: 500;
  }

  .header-title {
    margin: 0 0 30px;

    @include media-breakpoint-up(lg) {
      margin: 0 15px 0 -15px;
    }

    h1,
    h2 {
      padding: 30px 15px;
      margin: 0;
    }

    h1 {
      background: rgba($primary, 0.8);
      font-size: 44px;
      line-height: 50px;
      font-weight: 500;

      @include media-breakpoint-up(xl) {
        font-size: 56px;
        line-height: 56px;
      }
    }

    h2 {
      background: rgba($gray, 0.8);
      padding: 25px 15px;
      font-size: 26px;
      line-height: 38px;
      font-weight: 500;
      margin: 0 0 27px;

      @include media-breakpoint-up(lg) {
        margin: 0 40px 27px 0;
      }

      @include media-breakpoint-up(xl) {
        font-size: 28px;
        line-height: 38px;
      }
    }
  }

  .header-button {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @include media-breakpoint-up(lg) {
      .enroll-button,
      .keep-me-updated {
        flex: 1;
      }

      .keep-me-updated {
        margin-left: 15px;
      }
    }

    @include media-breakpoint-down(md) {
      .keep-me-updated {
        margin-left: 15px;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: stretch;

      .enroll-button,
      .keep-me-updated {
        margin-left: 0;
        margin-bottom: 15px;
        width: 100%;
        justify-content: center;
      }
    }
  }

  .action-button {
    font-size: x-large;
    font-weight: 400;
  }

  .enroll-button {
    display: inline-flex;
    margin: 0;
    border-radius: 5px;
    background-color: $primary;
    color: white;
    padding: 20px 40px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700;

    &.enrolled {
      border: 4px solid $primary;
      background-color: white;
      color: $primary;
    }

    &:hover {
      // underline is at different levels since we centered the text and checkmark vertically, so it looks strange
      text-decoration: none;
    }
  }

  .enroll-dropdown {
    .dropdown-toggle::after {
      display: none;
    }

    .login-popup {
      max-width: 350px;
      margin-left: 20px;
      margin-right: 20px;
      padding: 20px;
      font-weight: 600;
      font-size: 20px;

      .close-btn {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        margin: 20px 7px 0 0;
        font-size: 72px;
        font-weight: 300;
        background-color: #fff !important;
        color: #000;
        min-width: unset;
        height: unset;
        cursor: pointer;
      }

      h4 {
        font-weight: 700;
        font-size: 22px;
      }

      .popup-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;

        .link-button {
          border: 2px solid $light-blue;
          border-radius: 10px;
          font-weight: 600;
          font-size: 24px;

          &.sign-in-link {
            color: white;
            background-color: $light-blue;
          }

          &.create-account-link {
            color: black;
          }
        }
      }
    }

    .triangle {
      width: 15px;
      height: 15px;
      position: absolute;
      top: -7px;
      background: white;
      transform: rotate(45deg);
    }
  }

  .promo-video {
    width: 100%;
    border: 5px solid white;
    border-radius: 5px;
    margin: 0 0 20px;
    display: block;

    &.youtube-video {
      height: 260px;

      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }

  .video-caption {
    text-align: center;
    font-size: 18px;
  }

  .keep-me-updated {
    display: inline-flex;
    margin: 0;
    border-radius: 5px;
    padding: 20px 40px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700;
    background: $white;
    border: 1px solid $primary;
    white-space: nowrap;

    &:hover {
      // underline is at different levels since we centered the text and checkmark vertically, so it looks strange
      text-decoration: none;
      background: $close-hover-background;
    }
  }
}

.notifications {
  .alert {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}

.modal-content-signup {
  position: relative;
  width: 500px;
  background: $white;
  border-radius: 4px;
  color: $black;

  .container {
    display: block;
  }

  .close {
    opacity: 1;
  }

  .signup-dialog {
    text-align: center;
    line-height: 26px;

    h2 {
      font-weight: 700;
      font-size: 26px;
      color: $primary;
    }

    p {
      font-weight: 500;
      font-size: 20px;
    }
  }
}

.hubspot-form-container {
  form {
    width: 100%;
    height: auto;

    .hs-form-field {
      > label:first-child > span:first-child {
        padding-top: 16px;
        display: inline-block;
      }

      label {
        color: $hs-form-field-label;
        font-family: Rajdhani;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      select {
        height: 50px;
        width: 100%;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid $hs-form-field-border;
        background: $white;
        box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%);
        padding: 12px;
        font-family: Rajdhani;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        outline: none;

        &:focus {
          border: 2px solid $hs-form-field-border;
        }
      }

      .hs-error-msgs {
        padding: 0;
        margin: 0;
        list-style: none;

        label {
          color: $primary;
          display: block;
        }
      }

      .inputs-list,
      .multi-container {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;

        .hs-form-radio {
          label {
            input {
              height: 22px;
              position: absolute;
              width: 22px;
            }

            span {
              margin-left: 28px;
              margin-right: 32px;
            }

            input,
            span {
              cursor: pointer;
            }
          }
        }

        .hs-form-checkbox,
        .hs-form-booleancheckbox {
          span {
            margin-left: 8px;
            margin-right: 32px;
          }

          input,
          span {
            cursor: pointer;
          }
        }
      }
    }

    .hs-recaptcha,
    .hs-submit {
      padding-top: 16px;
    }

    .hs-submit {
      padding-bottom: 10px;
      text-align: right;

      input[type="submit"] {
        @extend .btn;
        @extend .btn-primary;

        border-radius: 5px;
        background: $primary;
        padding: 19px 25px;
        font-family: Rajdhani;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;

        &:hover {
          text-decoration: none;
          transition: all 0.2s ease-in;
        }
      }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .hs_error_rollup {
      display: none;
    }
  }
}
