// sass-lint:disable mixins-before-declarations

.header-holder {
  padding: 20px 0;

  @include media-breakpoint-down(md) {
    max-width: 100%;
  }

  .navbar {
    padding: 0;
  }

  .nowrap {
    flex-wrap: unset;
  }
}

.link-section.nowrap.login {
  ul {
    @include media-breakpoint-down(md) {
      margin: 0;
      text-align: right;

      li {
        padding: 0;

        a {
          padding: 5px 3px;
        }

        .dropdown-toggle {
          padding-right: 0;
        }
      }
    }
  }
}

.navbar-collapse {
  li {
    letter-spacing: normal;
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    padding: 5px 0 0 5px;

    a {
      padding: 5px 10px;
      color: black;
      text-decoration: none;
      display: block;

      &.blog-link {
        margin-right: 10px;
      }

      &:hover {
        color: $primary;
      }

      &.button {
        background: $primary;
        color: white;

        &:hover {
          background: black;
        }
      }
    }
  }
}

.navbar-collapse.collapsing,
.navbar-collapse.show {
  display: flex;
  flex-wrap: wrap;
}

.site-logo {
  width: 125px;
  height: 35px;
}

.xpro-link {
  z-index: 2;
  position: relative;
  padding: 10px 0 12px;
}

.navbar-toggler.nav-opener {
  border: 1px solid $primary;
  border-radius: 2px;
  color: $primary;
  margin: 5px 0 0;
  padding: 8px 6px 6px;

  .navbar-toggler-icon {
    width: 18px;
    height: 15px;
    display: inline-block;
    border: 1px solid $primary;
    border-width: 2px 0;
    position: relative;
    margin: -2px 5px 0 0;
    vertical-align: top;

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      content: "";
      top: 50%;
      border-top: 2px solid $primary;
      margin: -1px 0 0;
    }
  }
}
