.voucher-container {
  max-width: 800px;
  margin: auto;
  font-family: Rajdhani;
  font-size: 16px;

  .upload-gray {
    color: $light-gray;
  }

  a {
    color: $deep-sky-blue;
  }

  .btn {
    color: white;
    border-radius: 0;
    min-width: 180px;
    font-weight: 600;
    letter-spacing: 1.16px;
    line-height: 26px;
  }

  .voucher-card {
    @include media-breakpoint-down(sm) {
      padding: 20px;
    }

    @include media-breakpoint-down(xs) {
      padding: 15px 0;
    }

    background-color: $very-light-gray;
    border-width: 7px 0 0;
    border-style: solid;
    border-image-source: linear-gradient(90deg, #43bebc, #ebbb47);
    border-image-slice: 1;
    padding: 40px 137px;
  }

  .voucher-form {
    .form-control {
      font-size: inherit;
      font-weight: inherit;
    }

    input[type="file"] {
      color: $blue;
      font-weight: 600;
    }

    select {
      background-color: $white;
      min-width: 75%;
    }

    .form-error {
      font-size: 1rem;
      font-weight: 400;
    }

    .errored {
      border: 2px solid $red;
    }

    label {
      font-weight: 600;
    }

    .help-text {
      margin-top: 1rem;
    }

    .submit-row {
      margin-top: 25px;
    }
  }
}
