// sass-lint:disable mixins-before-declarations

$standard-padding: 25px;
$text-link-color: rgb(163 31 52);

h1,
h2,
h3,
h4,
h5 {
  -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
}

.course-catalog {
  background-color: #fff;

  .catalog-content {
    display: flex;
    margin: 0 206px;

    @include media-breakpoint-down(md) {
      display: contents;
    }

    @include media-breakpoint-down(lg) {
      margin: 0;
    }
  }

  header {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    padding: 30px 0;

    h1,
    h3 {
      padding: 0;
      margin: 0;
      max-width: 700px;
    }

    h1 {
      font-size: 34px;
      padding-bottom: 15px;
    }

    h3 {
      font-size: 26px;
      font-weight: 400;
    }
  }
}

.topics {
  padding-inline-start: 0;
  margin-top: 15%;
  margin-right: 18px;
  width: 231px;

  @include media-breakpoint-down(md) {
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-top: 35px;
    width: fit-content;
  }

  .topic {
    width: 220px;
    text-align: left;
    padding: 21px 15px;
    border-style: solid;
    border-radius: 6px;
    color: white;
    font-size: 18px;
    font-weight: 600;

    @include media-breakpoint-down(md) {
      height: 50px;
      width: fit-content;
      padding: 10px;
    }

    &.selected {
      background: #f3f3f7;

      &:last-child {
        margin-bottom: 10px;
      }

      a {
        color: $primary;
      }
    }

    a {
      color: $navy-blue;
      text-decoration-line: none;
    }
  }
}

.course-catalog .catalog-body {
  max-width: 860px;
  margin: 0 15px;

  @include media-breakpoint-up(sm) {
    margin: 0 auto;
  }

  .catalog-card {
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
    margin-bottom: 50px;
    border-radius: 5px;

    .rectangle {
      position: absolute;
      top: 12px;
      left: -10px;
      padding: 4px 19px;
      background-color: $featured-banner-background;
      color: white;
      font-size: 16px;
      letter-spacing: 1.05px;
      line-height: 26px;

      &::before {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        border: 14px solid $featured-banner-border;
        border-width: 7px 14px;
        border-color: $featured-banner-border $featured-banner-border
          transparent transparent;
      }
    }

    .lbl-toggle {
      font-size: 20px;
      color: $blue;
      font-weight: 500;
    }

    .top {
      box-shadow: 0 7px 6px -6px rgb(0 0 0 / 12.5%);
      padding: $standard-padding $standard-padding 0 $standard-padding;
      position: relative;

      @include media-breakpoint-up(md) {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
      }

      .cover-image,
      .top-level-details,
      .offering-type {
        flex: 1;
        padding-bottom: $standard-padding;
      }

      .cover-image {
        max-width: calc(240px + #{$standard-padding});
      }

      .top-level-details {
        min-width: 175px;
        max-width: 400px;
      }

      .offering-type {
        flex-grow: 0;
      }
    }

    .cover-image {
      .cover-image-frame {
        justify-content: center;
        overflow: hidden;
        height: 155px;
        max-width: 275px;
        border-radius: 10px;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
          margin: 0 $standard-padding 0 0;
        }

        img {
          height: 155px;
          object-fit: cover;
          width: 100%;
        }
      }
    }

    .top-level-details {
      h3,
      ul li {
        padding: 0;
      }

      h3 {
        padding-bottom: 15px;
        margin: 0;
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        color: $primary;

        a:link,
        a:visited {
          color: $text-link-color;
          text-decoration: none;
        }
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        color: $catalog-text;
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;

        strong {
          font-weight: 600;
        }

        li {
          padding-bottom: 10px;
        }
      }
    }

    .offering-type {
      label {
        display: inline-block;
        padding: 0 10px;
        color: #000;
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }

    .bottom {
      padding: 15px $standard-padding;

      .collapsible-menu {
        cursor: pointer;
      }

      .details-body {
        padding: 0 0 10px 20px;

        a.link-button {
          border: 1px solid $primary;
          border-radius: 5px;
          padding: 5px 10px;
          text-transform: uppercase;
        }

        ul.program-course-links {
          list-style-type: none;
          margin: 0 0 15px;
          padding: 0;

          a {
            &::after {
              font-family: "Material Icons";
              content: "chevron_right";
              font-size: 20px;
              display: inline-block;
              vertical-align: middle;
              margin-left: -5px;
            }
          }

          a:link,
          a:visited {
            color: $text-link-color;
          }
        }
      }
    }
  }
}
