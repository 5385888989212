@import "variables";
@import "node_modules/@material/layout-grid/dist/mdc.layout-grid";
@import "node_modules/@material/top-app-bar/dist/mdc.top-app-bar";
@import "node_modules/react-picky/dist/picky";
@import "node_modules/react-day-picker/lib/style";
@import "node_modules/bootstrap/scss/bootstrap";
@import "auth";
@import "common";
@import "top-app-bar";
@import "collapsible";
@import "catalog/body";
@import "catalog/keep-me-posted";
@import "catalog/banner";
@import "catalog/tabs";
@import "checkout";
@import "footer";
@import "slick";
@import "dashboard";
@import "blog";
@import "webinars/webinars";
@import "detail/header";
@import "detail/tiles";
@import "detail/learning-outcomes";
@import "detail/learning-techniques";
@import "detail/faqs";
@import "detail/for-teams";
@import "detail/who-should-enroll";
@import "detail/courses-in-program";
@import "detail/testimonial-carousel";
@import "detail/news-and-events-carousel";
@import "detail/faculty";
@import "detail/subnav";
@import "detail/text-video-section";
@import "detail/companies-trust";
@import "detail/text-section";
@import "detail/catalog-topics";
@import "detail/course-overview";
@import "ecommerce-admin";
@import "resource";
@import "notification";
@import "user-menu";
@import "error";
@import "voucher";
@import "certificates/certificate";
@import "expandable";
@import "receipt";
@import "enterprise";
@import "detail/enterprise/companies-logo-carousel";
@import "detail/enterprise/learning-journey";
@import "detail/enterprise/success-stories";
@import "detail/enterprise/learning-strategy-form";

body {
  font-family: Rajdhani, "Open Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  margin: 0;

  .main-panel {
    min-height: calc(100vh - 351px);
  }
}
